import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const AppNavbar = () => {
  const location = useLocation();

  // Function to determine if a link should have the active class
  const isActive = (path) => {
    console.warn(location.pathname);
    return location.pathname === path ? 'active' : '';
  };

  
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#dashboard">મિશન હોસ્પિટલ</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link  className={isActive('/')}>
              <Link to="/">Dashboard</Link>
            </Nav.Link>
            <Nav.Link className={isActive('/donation-list')}>
              <Link to="/donation-list">Donation Amount</Link>
            </Nav.Link>
            <Nav.Link className={isActive('/list')}>
              <Link to="/list">Donation Receipt</Link>
            </Nav.Link>
            <Nav.Link className={isActive('/logout')}>
              <Link to="/logout">Logout</Link>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
