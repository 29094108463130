import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppNavbar from "./AppNavbar"

const Home = () => {
    const navigate = useNavigate();
    const [list, setList] = useState(null);
    const [totalDonation, setTotalDonation] = useState(null);
    const [totalCredited, setTotalCredited] = useState(null);

    useEffect(() => {
        if (localStorage.getItem('login')) {
            // navigate('/list');
        } else {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        fetch(`https://hospital.rozeetechnist.com/hospital-api/dashboard.php`, {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({})
        })
            .then((response) => response.json())
            .then((result) => {
                setList(result.data);
                setTotalDonation(result.total_donation);
                setTotalCredited(result.total_credited_amount);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);


    return (
        <div>
            <AppNavbar />
            <section className="wrapper">
                <div className="container">
                    <div className="row top-page-bar">
                        <div className="col-md-8">
                            <div className="main-title">
                                <h1>Dashboard</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <main className="container">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='col-md-2 statetics-box total-box'>
                                <span className="city">ટોટલ</span>
                                <span className="donation">લખાવેલ : <span>{totalDonation}</span></span>
                                <span className="credited">જમા : <span>{totalCredited}</span></span>
                            </div>

                            {list && list.map((item, index) => (
                                <div key={index} className='col-md-2 statetics-box'>
                                    <span className="city">{item.city_name}</span>
                                    <span className="donation">લખાવેલ : <span>{item.total_donation_amount}</span></span>
                                    <span className="credited">જમા : <span>{item.total_credited_amount}</span></span>
                                </div>
                            ))}       
                        </div>
                    </div>
                </main>
            </section>
        </div>
    );
};

export default Home;