import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.getItem('login') ? (
        navigate('/')
    ) : (
        console.warn('Please login')
    );

  },[navigate]);

  const handleLogin = () => {
    setLoading(true);
        fetch("https://hospital.rozeetechnist.com/hospital-api/login.php", {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'username':username,
                'password':password
            })
        }).then((result) => {
            result.json().then((resp) => {
                if(resp.status){
                    setLoading(false);
                    localStorage.setItem('login',JSON.stringify(resp.data));
                    toast.success(resp.message);
                    navigate("/");                    
                }else{
                    toast.error(resp.message);
                    setLoading(false);
                }
            })
        })
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h2 className="text-center mb-4">Login</h2>
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? 'Wait...' : 'Login'}
                </button>
                
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
