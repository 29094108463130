// PDFComponent.js
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Document, Page, StyleSheet, Font, Text, View } from '@react-pdf/renderer';
import gujaratiFont from './NotoSansGujarati-VariableFont_wdth,wght.ttf';

// Register the Gujarati font
Font.register({
  family: 'GujaratiFont',
  src: gujaratiFont,
});

// Define styles for the table
const styles = StyleSheet.create({
  page: { flexDirection: 'row', backgroundColor: '#ffffff' },
  section: { margin: 10, padding: 10, flexGrow: 1 },
  table: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000' },
  tableRow: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000' },
  tableCol2: { width: '40%', borderRightWidth: 1, borderRightColor: '#000', padding: 2 },
  tableCol3: { width: '20%', borderRightWidth: 1, borderRightColor: '#000', padding: 2, textAlign:'right' },
  tableCol: { width: '20%', borderRightWidth: 1, borderRightColor: '#000', padding: 2 },

  tableCol6: { width: '10%', borderRightWidth: 1, borderRightColor: '#000', padding: 2 },
  tableCol4: { width: '15%', borderRightWidth: 1, borderRightColor: '#000', padding: 2 },
  tableCol5: { width: '30%', borderRightWidth: 1, borderRightColor: '#000', padding: 2 },
  tableCol7: { width: '15%', borderRightWidth: 1, borderRightColor: '#000', padding: 2, textAlign:'right' },

  lastCol: { borderRightWidth: 0 },
  text: { fontFamily: 'GujaratiFont', color: '#000', fontSize: '10px' }, // Set font and color
  headertext: { fontFamily: 'GujaratiFont', color: '#000', fontSize: '10px', fontWeight: "bold" }, // Set font and color
});


const PDFComponent = ({ city, filter, search,startdate,enddate }) => {

  const [data, setList] = useState(null);
  const [totalDonation, setTotalDonation] = useState(null);
  const [totalCredited, setTotalCredited] = useState(null);

  useEffect(() => {
    fetch(`https://hospital.rozeetechnist.com/hospital-api/exportReport.php`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        search: search,
        city: city,
        filter: filter,
        start_date:startdate,
        end_date:enddate
      })
    })
      .then((response) => response.json())
      .then((result) => {
        setList(result.data);
        setTotalDonation(result.total_donation);
        setTotalCredited(result.total_credited_amount);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, [city, filter, search,startdate,enddate]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
      {filter && filter == 'donation-list' ? (
        <View style={styles.section}>        
          {/* Table Header */}
          <View style={styles.table}>
            <Text style={[styles.tableCol6, styles.lastCol, styles.headertext]}>પહોચ ન.</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>તારીખ</Text>
            <Text style={[styles.tableCol5, styles.lastCol, styles.headertext]}>નામ</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>ગામ</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>ડોનેશન ટાઇપ</Text>
            <Text style={[styles.tableCol7, styles.lastCol, styles.headertext]}>લખાવેલ રકમ</Text>
          </View>

          {/* Table Data */}
          {data && data.map((row, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCol6, styles.lastCol, styles.text]}>{row.receipt_no}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.donation_date}</Text>
              <Text style={[styles.tableCol5, styles.lastCol, styles.text]}>{row.name}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.city_name}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.type_of_donation}</Text>
              <Text style={[styles.tableCol7, styles.lastCol, styles.text]}>{row.donation_amount}</Text>
            </View>
          ))}       
             <View style={styles.tableRow}>
              <Text style={[styles.tableCol6, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol5, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>ટોટલ</Text>
              <Text style={[styles.tableCol7, styles.lastCol, styles.text]}>{totalDonation}</Text>
            </View>   
        </View>             
      ): filter == 'receipt-list' ? (
        <View style={styles.section}>        
          {/* Table Header */}
          <View style={styles.table}>
            <Text style={[styles.tableCol6, styles.lastCol, styles.headertext]}>પહોચ ન.</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>તારીખ</Text>
            <Text style={[styles.tableCol5, styles.lastCol, styles.headertext]}>નામ</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>ગામ</Text>
            <Text style={[styles.tableCol4, styles.lastCol, styles.headertext]}>ડોનેશન ટાઇપ</Text>
            <Text style={[styles.tableCol7, styles.lastCol, styles.headertext]}>જમા કરેલ રક્મ</Text>
          </View>

          {/* Table Data */}
          {data && data.map((row, index) => (
            <View key={index} style={styles.tableRow}>
              <Text style={[styles.tableCol6, styles.lastCol, styles.text]}>{row.receipt_no}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.donation_date}</Text>
              <Text style={[styles.tableCol5, styles.lastCol, styles.text]}>{row.name}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.city_name}</Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>{row.type_of_donation}</Text>
              <Text style={[styles.tableCol7, styles.lastCol, styles.text]}>{row.credited_amount}</Text>
            </View>
          ))}       
            <View style={styles.tableRow}>
              <Text style={[styles.tableCol6, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol5, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}></Text>
              <Text style={[styles.tableCol4, styles.lastCol, styles.text]}>ટોટલ</Text>
              <Text style={[styles.tableCol7, styles.lastCol, styles.text]}>{totalCredited}</Text>
            </View>   
        </View>             
      ):(
        <View style={styles.section}>        
        {/* Table Header */}
        <View style={styles.table}>        
          <Text style={[styles.tableCol2, styles.lastCol, styles.headertext]}>નામ</Text>
          <Text style={[styles.tableCol, styles.lastCol, styles.headertext]}>ગામ</Text>
          <Text style={[styles.tableCol3, styles.lastCol, styles.headertext]}>લખાવેલ રકમ</Text>
          <Text style={[styles.tableCol3, styles.lastCol, styles.headertext]}>જમા કરેલ રક્મ</Text>
        </View>

        {/* Table Data */}
        {data && data.map((row, index) => (
          <View key={index} style={styles.tableRow}>          
            <Text style={[styles.tableCol2, styles.lastCol, styles.text]}>{row.name}   </Text>
            <Text style={[styles.tableCol, styles.lastCol, styles.text]}>{row.city_name}</Text>
            <Text style={[styles.tableCol3, styles.lastCol, styles.text]}>{row.total_donation_amount}</Text>
            <Text style={[styles.tableCol3, styles.lastCol, styles.text]}>{row.total_credited_amount}</Text>
          </View>
        ))}          
          <View style={styles.tableRow}>          
            <Text style={[styles.tableCol2, styles.lastCol, styles.text]}></Text>
            <Text style={[styles.tableCol, styles.lastCol, styles.text]}>ટોટલ</Text>
            <Text style={[styles.tableCol3, styles.lastCol, styles.text]}>{totalDonation}</Text>
            <Text style={[styles.tableCol3, styles.lastCol, styles.text]}>{totalCredited}</Text>
          </View>
      </View>
      )}
      </Page>
    </Document>
  );

};
PDFComponent.propTypes = {
  city: PropTypes.string.isRequired
}
PDFComponent.propTypes = {
  filter: PropTypes.string.isRequired
}
PDFComponent.propTypes = {
  search: PropTypes.string.isRequired
}
PDFComponent.propTypes = {
  startdate: PropTypes.string.isRequired
}
PDFComponent.propTypes = {
  enddate: PropTypes.string.isRequired
}

export default PDFComponent;
