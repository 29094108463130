
import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPlus, faEdit, faPrint, faTrash, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { InputGroup, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import AppNavbar from "./AppNavbar"
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFComponent from './PDFComponent';

const DonationList = () => {
    const navigate = useNavigate();
    const [list, setList] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerms, setSearchTerms] = useState("");
    const [city, setCity] = useState("");
    const [device, setDevice] = useState("");

    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [filterOptions, setFilterOptions] = useState([]);
    const [filter, setFilter] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        if (localStorage.getItem('login')) {
            console.warn("hello")
        } else {
            navigate('/login');
        }
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        console.warn(isMobile);
        setDevice(isMobile);

    }, [navigate]);


    useEffect(() => {

        getData();

        const fetchData = async () => {
            try {

                const response = await fetch(
                    'https://hospital.rozeetechnist.com/hospital-api/getCities.php'
                );
                const result = await response.json();
                setDropdownOptions(result);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        setFilterOptions([{ 'value': "donation-list", "label": "Donation List" }, { 'value': "all", "label": "All" }, { 'value': "partial", "label": "Partial" }, { 'value': "full", "label": "Full" }])

    }, [currentPage, itemsPerPage, searchTerms, city, filter,startDate,endDate]);


    const handleSelectChange = (selectedOption) => {
        setCity((prevData) => ({
            ...prevData,
            city: selectedOption, // Store only the value property
        }));
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };
    
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    

    const handleFilterChange = (selectedOption) => {
        setFilter((prevData) => ({
            ...prevData,
            filter: selectedOption, // Store only the value property
        }));
    };

    const getData = () => {
 
        // Replace 'your-api-endpoint' with the actual server-side pagination endpoint
        fetch(`https://hospital.rozeetechnist.com/hospital-api/getDonationsAmount.php`, {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                start: currentPage,
                per_page: itemsPerPage,
                search: searchTerms,
                city: city,
                start_date:startDate,
                end_date:endDate
            })
        })
        .then((response) => response.json())
        .then((result) => {
            setItemsPerPage(10);
            setTotalItems(result.total_records);
            setList(result.data);
        })
        .catch((error) => console.error('Error fetching data:', error));
    };

    const deleteItem = (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this item?');

        if (isConfirmed) {
            fetch(`https://hospital.rozeetechnist.com/hospital-api/deleteDonationAmount.php`, {
                method: "Delete",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    id: id
                })
            })
                .then((result) => result.json())
                .then((resp) => {
                    if (resp.status) {
                        getData();
                        toast.success(resp.message);
                    } else {
                        toast.error(resp.message);
                    }
                })
                .catch((error) => console.error('Error deleting restaurant:', error));
        }
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // const exportReport = () => {
    // // Replace 'your-api-endpoint' with the actual server-side pagination endpoint
    //     fetch(`https://hospital.rozeetechnist.com/hospital-api/exportReport.php`, {
    //         method: "Post",
    //         headers: {
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify({
    //             search: searchTerms,
    //             city: city,
    //             filter:filter
    //         })
    //     })
    //     .then((response) => response.json())
    //     .then((result) => {
    //         console.warn(result);
    //     })
    //     .catch((error) => console.error('Error fetching data:', error));
    // };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handlePrint = (data) => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<html><head><title>Receipt</title>');

        // Add your custom CSS styles, including @media print rules
        printWindow.document.write('<style>');
        printWindow.document.write(`
        .buttons span,.input,.receipt{border:1px solid #ddd}body{margin:0;padding:0;size:A6 portrait}@page{size:A6 portrait}@media print{span{print-color-adjust:exact}}.receipt{padding:10px}.header{font-size:27px;font-weight:700;text-align:center;padding:21px 0}table{width:100%}.input{height:36px;min-width:200px;display:inline-block;vertical-align:middle;line-height:2;padding:0 9px}.datebox{width:103px!important}.namebox.input{width:84.4%}.col td{padding-bottom:12px!important}.buttons span{padding:6px 28px;color: gray;}.buttons span.active{color:#000}.active span {width: auto;  padding: unset;  border: none;font-size: 22px;vertical-align: middle;color:#000000}.active span img {width: 17px;}
        `);
        printWindow.document.write('</style>');

        printWindow.document.write('</head><body>');
        printWindow.document.write(`
        <div class="receipt">
        <div class="header" style="color:#DF3F3F;">
            મિશન હોસ્પિટલ
        </div>
        <table>
            <tbody>
                <tr class="col">
                    <td>તારીખ : <span class="datebox input">`+ data.donation_date + `</span></td>            
                    <td>પહોંચ નંબર : <span class="receiptnumber input">`+ data.receipt_no + `</span></td>
                    <td>ગામ : <span class="villagebox input">`+ data.city_name + `</span></td>
                </tr>
                <tr class="col">
                    <td colspan="3">નામ : <span class="namebox input">`+ data.name + `</span></td>  
                </tr>
                <tr class="col">
                    <td colspan="3">આપના તરફથી મિશન હોસ્પિટલ ને : <span class="buttons">                    
                        <span  class="${data.type_of_donation == 'ઇમદાદ' ? `active` : ''}">${data.type_of_donation == 'ઇમદાદ' ? `<span><img src="https://hospital.rozeetechnist.com/check-mark.png"></span>` : ''} ઇમદાદ</span>
                        <span  class="${data.type_of_donation == 'જકાત' ? `active` : ''}"> ${data.type_of_donation == 'જકાત' ? `<span><img src="https://hospital.rozeetechnist.com/check-mark.png"></span>` : ''} જકાત</span>
                        <span class="${data.type_of_donation == 'ઉસર' ? `active` : ''}">${data.type_of_donation == 'ઉસર' ? `<span><img src="https://hospital.rozeetechnist.com/check-mark.png"></span>` : ''} ઉસર</span>
                        <span  class="${data.type_of_donation == 'અન્ય' ? `active` : ''}">${data.type_of_donation == 'અન્ય' ? `<span><img src="https://hospital.rozeetechnist.com/check-mark.png"></span>` : ''} અન્ય</span>
                    </span></td>    
                </tr>
              <tr class="col">
                    <td colspan="3">
                        રૂ <span class="donationamountbox input">`+ data.donation_amount + `/-</span> લખાવેલ તે બદલ મિશન હોસ્પિટલ આપનો સુખ્રીયા / આભાર માને છે.
                    </td>            
                </tr> 
                <tr class="col">
                   <td colspan="2">
                        
                    </td> 
                    <td style="text-align: center;">
                        સહી<br><br>મિશન હોસ્પિટલ 
                         
                    </td>           
                </tr>
            </tbody>
        </table>
    </div>    
        `);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    const generatePDFContent = () => {
        // Implement logic to generate PDF content based on the selected city
        return <PDFComponent city={city && city.city ? city.city.value : ''} filter={filter && filter.filter ? filter.filter.value : ''} search={searchTerms ? searchTerms : ''} startdate={startDate ? startDate : ''} enddate={endDate ? endDate : ''} />;
    };

    const getPaginationItems = () => {
        const pageNumbers = [];
        let startPage = currentPage - 2;
        let endPage = currentPage + 2;

        if (startPage <= 0) {
            startPage = 1;
            endPage = 5;
        }

        if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - 4;
            if (startPage <= 0) {
                startPage = 1;
            }
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Pagination.Item key={i} active={i === currentPage} onClick={() => paginate(i)}>
                    {i}
                </Pagination.Item>
            );
        }

        return pageNumbers;
    };

    return (
        <div>
            <AppNavbar />

            <section className="wrapper">

                <div className="container">
                    <div className="row top-page-bar">
                        <div className="col-md-4">
                            <div className="main-title">
                                <h1>Donation Amount</h1>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="search-area">
                                <InputGroup>
                                    <FormControl
                                        placeholder="Search"
                                        aria-label="Search term"
                                        aria-describedby="basic-addon2"
                                        onChange={(e) => setSearchTerms(e.target.value)}
                                    />
                                    <InputGroup.Text></InputGroup.Text>
                                    <Select
                                        name="city"
                                        onChange={handleSelectChange}
                                        options={dropdownOptions}
                                        placeholder="ગામ"
                                    />
                                    <InputGroup.Text></InputGroup.Text>
                                    <Select
                                        name="filter"
                                        onChange={handleFilterChange}
                                        options={filterOptions}
                                        placeholder="ફિલ્ટર"
                                    />
                                    <InputGroup.Text></InputGroup.Text>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={handleStartDateChange}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText='Start Date'
                                        className="form-control" // Add this class to ensure DatePicker aligns correctly
                                    />
                                    <InputGroup.Text></InputGroup.Text>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={handleEndDateChange}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText='End Date'
                                        className="form-control" // Add this class to ensure DatePicker aligns correctly
                                    />
                                    <div className="buttons filters">
                                        {/* <span className="mr-3"><FontAwesomeIcon icon={faFilter}></FontAwesomeIcon></span> */}
                                        <PDFDownloadLink document={generatePDFContent()} fileName="document.pdf">
                                            {({ loading }) =>
                                                loading ? '...' : <FontAwesomeIcon icon={faFilePdf} />
                                            }
                                        </PDFDownloadLink>
                                    </div>
                                </InputGroup>
                            </div>


                        </div>
                    </div>
                </div>

                <main className="container">
                    {list ? (
                        <>
                            {device ? (
                                <div>
                                    <div className='mobile_view'>
                                        {list.map((item) => (
                                            <div key={item.id}>
                                                <div className='row box'>
                                                    <div className="col">
                                                        <span className="r-label">પહોચ ન. : </span>
                                                        <span className="r-value">{item.receipt_no}</span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="r-label">તારીખ : </span>
                                                        <span className="r-value">{item.donation_date}</span>
                                                    </div>
                                                    <div className="col-md-12">

                                                        <span className="r-value receipt_name"><Link to={`/donation-update/${item.id}`}>નામ : {item.name}</Link></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="r-label">ગામ :</span>
                                                        <span className="r-value">{item.city_name}</span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="r-label">ડોનેશન ટાઇપ :</span>
                                                        <span className="r-value typeofdonation">{item.type_of_donation}</span>
                                                    </div>
                                                    <div className="mt-3"></div>
                                                    <div className="col">
                                                        <span className="r-label">લખાવેલ રકમ :</span>
                                                        <span className="r-value">{item.donation_amount}/-</span>
                                                    </div>
                                                    <div className="mt-3"></div>
                                                    <div className="col-md-12 table-buttons">
                                                        <span className="editbtn"><Link to={`/create/${item.id}`}>
                                                            Receipt
                                                        </Link></span>
                                                        <span className="deletebtn" onClick={() => deleteItem(item.id)}>
                                                            Delete
                                                        </span>
                                                        <span className="printbtn" onClick={() => handlePrint(item)}>
                                                            Print
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>પહોચ ન.</th>
                                                <th>તારીખ</th>
                                                <th>નામ</th>
                                                <th>ગામ</th>
                                                <th>ડોનેશન ટાઇપ</th>
                                                <th>લખાવેલ રકમ</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{item.receipt_no}</td>
                                                    <td>{item.donation_date}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.city_name}</td>
                                                    <td>{item.type_of_donation}</td>
                                                    <td>{item.donation_amount}</td>
                                                    <td className="table-action">
                                                        <span><Link to={`/donation-update/${item.id}`}>
                                                            <FontAwesomeIcon icon={faEdit} color="green" />
                                                        </Link></span>
                                                        <span><Link to={`/create/${item.id}`}>
                                                            <FontAwesomeIcon icon={faReceipt} color="black" />
                                                        </Link></span>
                                                        <span onClick={() => deleteItem(item.id)}>
                                                            <FontAwesomeIcon icon={faTrash} color="red" />
                                                        </span>
                                                        <span onClick={() => handlePrint(item)}>
                                                            <FontAwesomeIcon icon={faPrint} color="grey" />
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </>
                            )}
                        </>
                    ) : (
                        <p>No data found</p>
                    )}
                   <Pagination>
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {getPaginationItems()}
                        <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages} />
                    </Pagination>
                    <div className="createButton">
                        <Link to={`/donation-create`}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Link>
                    </div>
                </main>
            </section>
        </div>
    );
};

export default DonationList;
