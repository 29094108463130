import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom"

import Home from "./components/Home"
import RestaurantCreate from "./components/RestaurantCreate"
import RestaurantList from "./components/RestaurantList"
import Logout from "./components/Logout"
import RestaurantUpdate from "./components/RestaurantUpdate"
import Login from './components/Login';

import DonationList from "./components/DonationList"
import DonationCreate from "./components/DonationCreate"
import DonationUpdate from "./components/DonationUpdate"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/list" element={<RestaurantList />}></Route>
          <Route path="/create/:id" element={<RestaurantCreate />}></Route>
          <Route path="/update/:id" element={<RestaurantUpdate />}></Route>          
          <Route path="/logout" element={<Logout />}></Route>

          <Route path="/donation-list" element={<DonationList />}></Route>
          <Route path="/donation-create" element={<DonationCreate />}></Route>
          <Route path="/donation-update/:id" element={<DonationUpdate />}></Route>          
          
        </Routes>
      </Router>

    </div>
  );
}

export default App;
