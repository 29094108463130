import React, { useEffect } from 'react';
import {
    useNavigate
  } from "react-router-dom"

  
const Logout = () => {
    localStorage.clear();
    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem('login')) {
            console.warn("hello")
        } else {
            navigate('/login');
        }
    }, []);

    
    return (
        <div>
            
        </div>
    );
};

export default Logout;