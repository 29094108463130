import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

import AppNavbar from "./AppNavbar";

const CustomInput = React.forwardRef(({ value, onClick, onChange }, ref) => (
  <InputMask
    mask="99/99/9999"
    value={value}
    onChange={onChange}
  >
    {(inputProps) => (
      <input
        {...inputProps}
        ref={ref}
        onClick={onClick}
        className="form-control"
        placeholder="તારીખ"
      />
    )}
  </InputMask>
));

CustomInput.displayName = 'CustomInput';

CustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

const DonationCreate = () => {
  const [donationData, setDonationData] = useState({
    date: new Date(),
    name: "",
    city: "",
    type_of_donation: "",
    donation_amount: ""
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);

  useEffect(() => {
    if (!localStorage.getItem('login')) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://hospital.rozeetechnist.com/hospital-api/getCities.php');
        const result = await response.json();
        setDropdownOptions(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDonationData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setDonationData((prevData) => ({
      ...prevData,
      city: selectedOption,
    }));
  };

  const handleCreate = () => {
    setLoading(true);
    fetch("https://hospital.rozeetechnist.com/hospital-api/createDonationAmount.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(donationData)
    }).then((result) => {
      result.json().then((resp) => {
        setLoading(false);
        if (resp.status) {
          toast.success(resp.message);
          navigate("/donation-list");
        } else {
          toast.error(resp.message);
        }
      });
    });
  };

  return (
    <div>
      <AppNavbar />
      <div className="container">
        <div className="row top-page-bar">
          <div className="col-9">
            <div className="main-title">
              <h1>Add New Donation Amount</h1>
            </div>
          </div>
          <div className="col-3">
            <div className="search-area">
              <div className="buttons">
                <Link className='btn btn-secondary' to="/donation-list">Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row g-3">
          <div className="col-md-3">
            <label className="form-label">Date</label>
            <div>
              <DatePicker
                selected={donationData.date}
                onChange={(date) => setDonationData(prevData => ({ ...prevData, date: date }))}
                dateFormat="dd/MM/yyyy"
                customInput={<CustomInput />}
              />
            </div>
          </div>
          <div className="col-md-3">
            <label className="form-label">Name</label>
            <input className="form-control" name="name" onChange={handleChange} placeholder='નામ' />
          </div>
          <div className="col-md-3">
            <label className="form-label">City/Village</label>
            <Select
              value={donationData.city}
              name="city"
              onChange={handleSelectChange}
              options={dropdownOptions}
              placeholder="ગામ"
            />
          </div>
          <div className="col-md-12">
            <label className="form-label">Type of Donation</label>
            <div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_of_donation"
                  id="imdad"
                  value="imdad"
                  checked={donationData.type_of_donation === 'imdad'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="imdad">
                  ઇમદાદ
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_of_donation"
                  id="jakat"
                  value="jakat"
                  checked={donationData.type_of_donation === 'jakat'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="jakat">
                  જકાત
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_of_donation"
                  id="usar"
                  value="usar"
                  checked={donationData.type_of_donation === 'usar'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="usar">
                  ઉસર
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="type_of_donation"
                  id="other"
                  value="other"
                  checked={donationData.type_of_donation === 'other'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="other">
                  અન્ય
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <label className="form-label">Donation Amount (લખાવેલા)</label>
            <input className="form-control" onChange={handleChange} name="donation_amount" placeholder='ટૉટલ લખાવેલ રકમ' />
          </div>
          <div className="col-12">
            <button className="btn btn-primary btn-lg" onClick={handleCreate} disabled={loading}>
              {loading ? 'Loading...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationCreate;
